import { RemoteConfigs } from '@/lib/firebase/config/configs'
import { RemoteEnvs } from '@/lib/firebase/config/envs'
import { RemoteExperiments } from '@/lib/firebase/config/experiments'
import { RemoteToggles } from '@/lib/firebase/config/toggles'
import {
  type ExperimentParamKey,
  type ExperimentValue,
  type ExtractKeys,
  type RemoteParameterOptionalTracking,
  type RemoteParameterRequiredTracking,
  type ToggleParamKey,
  type ToggleValue,
  type TrackingKey,
} from '@/lib/firebase/types'

export const DefinedFirebaseConfig = {
  ...RemoteConfigs,
  ...RemoteEnvs,
  ...RemoteExperiments,
  ...RemoteToggles,
}

export const RemoteParamKeys: ExtractKeys<typeof DefinedFirebaseConfig> = Object.fromEntries(
  Object.entries(DefinedFirebaseConfig).map(([k, v]) => [k, v.key])
) as ExtractKeys<typeof DefinedFirebaseConfig>

const TrackedConfig: Array<
  | RemoteParameterOptionalTracking<ToggleParamKey, ToggleValue>
  | RemoteParameterRequiredTracking<ExperimentParamKey, ExperimentValue>
> = Object.values({
  ...RemoteToggles,
  ...RemoteExperiments,
})

export const TRACKING_KEYS = TrackedConfig.reduce(
  (acc, config) => {
    const { key, trackingKey } = config
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Ignore this error about missing tracking keys.
    if (trackingKey) {
      return {
        KEY_TO_TRACKING_KEY: {
          ...acc.KEY_TO_TRACKING_KEY,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore Ignore this error about missing tracking keys.
          [key]: trackingKey,
        },
        TRACKING_KEY_TO_KEY: {
          ...acc.TRACKING_KEY_TO_KEY,
          [trackingKey]: key,
        },
      }
    }

    return acc
  },
  {} as {
    KEY_TO_TRACKING_KEY: { [key: string]: TrackingKey }
    TRACKING_KEY_TO_KEY: {
      [key: TrackingKey]: string
    }
  }
)

export const DEPRECATED_TRACKING_KEYS = new Set<TrackingKey>(['kjca1733'])
