import { useContext } from 'react'

import { ParamsContext } from '@/lib/firebase/providers/RemoteConfigProvider'
import { type ExperimentParamKey } from '@/lib/firebase/types'

export const useExperiment = (key: ExperimentParamKey) => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useContext must be used within a RemoteConfigProvider')
  }
  return context.getExperiment(key)
}
