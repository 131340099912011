/**
 * Remote Environments Configuration - Environment Variables for Dynamic Configurations
 *
 * Use this object to define all environment variables used in the application.
 * Each environment variable must follow the `RemoteEnvType` structure.
 *
 * Example:
 * ```
 * CDN_URL: {
 *   key: 'env_cdn_url',
 *   defaultValue: {
 *     int: 'media.ca-kijiji-int.ca',
 *     prod: 'media.ca-kijiji-prod.ca',
 *   },
 * }
 * ```
 * **Key Guidelines:**
 * - `key`: Must start with the prefix `env_` followed by a descriptive name indicating the variable's purpose.
 * - Environment variables defined here are typically used for server-side configurations, build-time constants, or dynamic runtime behaviors.
 */
import { type RemoteEnvType } from '@/lib/firebase/types'

export const RemoteEnvs = {} as const satisfies RemoteEnvType
