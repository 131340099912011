import {
  type UserLocation,
  useGetLocationFromIdLazyQuery,
  useGetUserLocationQuery,
} from '@kijiji/generated/graphql-types'
import { useCallback, useEffect } from 'react'

import {
  DEFAULT_USER_LOCATION,
  MAXIMUM_RADIUS_VALUE,
  MINIMUM_RADIUS_VALUE,
  RADIUS_SLIDER_HALF_POINT_KMS,
} from '@/constants/location'
import { convertLocationToLatestSearchCookie } from '@/domain/location/convertLocationToLatestSearchCookie'
import {} from '@/domain/location/getRecentLocationsFromLocalStorage'
import { setRadiusBoostCookie } from '@/domain/location/setRadiusBoostCookie'
import { updateLatestSearchCookie } from '@/utils/cookies/latestSearchCookie'
import { updateUserLocationCache } from '@/utils/location/location'

export const useGetLocation = (): {
  location: UserLocation
  updateUserLocation: (newLocation: UserLocation) => void
  updateUserLocationById: (id: number) => void
} => {
  const { data: { userLocation } = {}, client } = useGetUserLocationQuery()
  const [fetchLocation] = useGetLocationFromIdLazyQuery({ fetchPolicy: 'cache-first' })

  const location = userLocation || DEFAULT_USER_LOCATION

  const updateUserLocation = useCallback(
    (newLocation: UserLocation) => {
      let userRadius = newLocation.area?.radius ?? RADIUS_SLIDER_HALF_POINT_KMS

      //if radius is above Maximum value, set it to maximum value
      //else  if radius is below Minimum value, set it to minimum value
      if (userRadius > MAXIMUM_RADIUS_VALUE) {
        userRadius = MAXIMUM_RADIUS_VALUE
      } else if (userRadius < MINIMUM_RADIUS_VALUE) {
        userRadius = MINIMUM_RADIUS_VALUE
      }

      /** Update Cache */
      updateUserLocationCache(
        {
          ...newLocation,
          area: newLocation.area
            ? {
                ...newLocation.area,
                radius: userRadius,
              }
            : null,
        },
        client
      )

      /** Update cookies */
      const legacyCookieLocation = convertLocationToLatestSearchCookie(newLocation)
      updateLatestSearchCookie(legacyCookieLocation)

      /** Should reset local storage value if user change location to a region*/
      location.isRegion && setRadiusBoostCookie(false)
    },
    [client, location.isRegion]
  )

  useEffect(() => {
    if (!location.area) return

    const locationRadius = location.area.radius

    if (locationRadius > MAXIMUM_RADIUS_VALUE || locationRadius < MINIMUM_RADIUS_VALUE) {
      //just calling this method will update the cache and cookies with the correct radius
      updateUserLocation(location)
    }
  }, [client, location, updateUserLocation])

  const updateUserLocationById = async (id: number) => {
    const { data } = await fetchLocation({
      variables: { locationId: id },
    })
    if (!data?.location?.name) return
    const newLocation: UserLocation = {
      id: data.location.id,
      isRegion: true,
      name: data.location.name,
      area: null,
    }

    updateUserLocation(newLocation)
  }

  return { location, updateUserLocation, updateUserLocationById }
}
