import { useContext } from 'react'

import { ParamsContext } from '@/lib/firebase/providers/RemoteConfigProvider'

export const useTrackingKeys = () => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useTrackingKeys must be used within a RemoteConfigProvider')
  }
  const keysToTrack = context.getAllTracked()

  if (!keysToTrack.length) return ''

  const experimentsDataLayerString = keysToTrack.reduce(
    (acc, { key, value }) => (value >= 0 ? `${acc}${key}=${value};` : acc),
    ''
  )

  return experimentsDataLayerString
}
