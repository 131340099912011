/**
 * Remote Experiments Configuration - A/B Testing & Controlled Experiments
 *
 * Use this object to define all experiments used in the application.
 * Each experiment must follow the `RemoteExperimentType` structure.
 *
 * Example:
 * ```
 * PINK_HEADER_TEST: {
 *   key: 'exp_kjca123_pink_header',
 *   trackingKey: 'kjca123',
 *   defaultValue: {
 *     int: ExperimentValues.INTERNAL_ON,
 *     prod: ExperimentValues.EVERYONE_ELSE,
 *   },
 * }
 * ```
 * **Key Guidelines:**
 * - `key`: Must start with the prefix `exp_` followed by a unique identifier. Use a descriptive name indicating the experiment's purpose.
 * - `trackingKey`: A key prefixed by `kjca` used for tracking. Should align with story JIRA ticket.
 * - `defaultValue`: An object defining the default/fallback values for both integration (`int`) and production (`prod`) environments.
 */
import { type RemoteExperimentType, ExperimentValues } from '@/lib/firebase/types'

export const RemoteExperiments = {
  BEST_MATCH_ALGORITHM_TEST: {
    key: 'exp_best_match_algorithm',
    defaultValue: { int: ExperimentValues.GROUP_A, prod: ExperimentValues.EVERYONE_ELSE },
    trackingKey: 'kjca4700',
  },
  DEDUPLICATION_TEST: {
    key: 'exp_deduplication',
    defaultValue: { int: ExperimentValues.GROUP_A, prod: ExperimentValues.EVERYONE_ELSE },
    trackingKey: 'kjca5530',
  },
  SELLER_RESPONSIVENESS_TEST: {
    key: 'exp_seller_responsiveness',
    defaultValue: { int: ExperimentValues.GROUP_A, prod: ExperimentValues.EVERYONE_ELSE },
    trackingKey: 'kjca5475',
  },
  IMAGELESS_DOWN_BOOSTING_TEST: {
    key: 'exp_imageless_down_boosting',
    defaultValue: { int: ExperimentValues.GROUP_A, prod: ExperimentValues.EVERYONE_ELSE },
    trackingKey: 'kjca5002',
  },
  SRP_ADS_REINVENTION_TEST: {
    key: 'exp_srp_ads_reinvention',
    defaultValue: { int: ExperimentValues.GROUP_A, prod: ExperimentValues.EVERYONE_ELSE },
    trackingKey: 'kjca6267',
  },
  SRP_RENTAL_LISTING_TITLES_TEST: {
    key: 'exp_srp_rental_listing_titles',
    defaultValue: { int: ExperimentValues.GROUP_A, prod: ExperimentValues.EVERYONE_ELSE },
    trackingKey: 'kjca5705',
  },
} as const satisfies RemoteExperimentType
