import { useContext } from 'react'

import { ParamsContext } from '@/lib/firebase/providers/RemoteConfigProvider'
import { type ToggleParamKey } from '@/lib/firebase/types'

export const useToggle = (key: ToggleParamKey) => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useToggle must be used within a RemoteConfigProvider')
  }

  return context.getToggle(key)
}
