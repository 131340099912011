import { useContext } from 'react'

import { ParamsContext } from '@/lib/firebase/providers/RemoteConfigProvider'
import { type ConfigParamKey } from '@/lib/firebase/types'

export const useConfig = (key: ConfigParamKey) => {
  const context = useContext(ParamsContext)
  // throw only in development, log error in production and return undefined
  if (!context) {
    if (process.env.NODE_ENV === 'production') {
      return undefined
    }
    throw new Error('useConfig must be used within a RemoteConfigProvider')
  }
  return context.getConfig(key)
}
